// extracted by mini-css-extract-plugin
export var borderChecked = "ProjectCloudTypeRadioTile__borderChecked__DC6Lh";
export var borderUnchecked = "ProjectCloudTypeRadioTile__borderUnchecked__Joj52";
export var column = "ProjectCloudTypeRadioTile__column__V4Ymg";
export var flex = "ProjectCloudTypeRadioTile__flex__pLX96";
export var flexColumn = "ProjectCloudTypeRadioTile__flexColumn__SHjA7";
export var gap1 = "ProjectCloudTypeRadioTile__gap1__ddybu";
export var gap2 = "ProjectCloudTypeRadioTile__gap2__W_a6N";
export var gap3 = "ProjectCloudTypeRadioTile__gap3__ujGAw";
export var gap4 = "ProjectCloudTypeRadioTile__gap4__DdFEU";
export var gap5 = "ProjectCloudTypeRadioTile__gap5__ddo16";
export var headingAndDescriptionWrapper = "ProjectCloudTypeRadioTile__headingAndDescriptionWrapper__SQv05";
export var row = "ProjectCloudTypeRadioTile__row__XcOwV";
export var wrapper = "ProjectCloudTypeRadioTile__wrapper__aKyF6";