// extracted by mini-css-extract-plugin
export var cloudDescription = "CreateCloudProjectCloudTypeSelection__cloudDescription__oj26a";
export var cloudHeading = "CreateCloudProjectCloudTypeSelection__cloudHeading__NimjL";
export var cloudTextContainer = "CreateCloudProjectCloudTypeSelection__cloudTextContainer__qoXWf";
export var column = "CreateCloudProjectCloudTypeSelection__column__VP4Ly";
export var container = "CreateCloudProjectCloudTypeSelection__container__Wf9Ye";
export var contentWrapper = "CreateCloudProjectCloudTypeSelection__contentWrapper__YkIuU";
export var flex = "CreateCloudProjectCloudTypeSelection__flex__B8vuG";
export var flexColumn = "CreateCloudProjectCloudTypeSelection__flexColumn__Qmr6r";
export var gap1 = "CreateCloudProjectCloudTypeSelection__gap1__xBcMn";
export var gap2 = "CreateCloudProjectCloudTypeSelection__gap2__Sr5Wh";
export var gap3 = "CreateCloudProjectCloudTypeSelection__gap3__CzSdL";
export var gap4 = "CreateCloudProjectCloudTypeSelection__gap4__L_83r";
export var gap5 = "CreateCloudProjectCloudTypeSelection__gap5__vkxQj";
export var howItWorksTooltip = "CreateCloudProjectCloudTypeSelection__howItWorksTooltip__xRtnQ";
export var row = "CreateCloudProjectCloudTypeSelection__row__KdAGY";
export var tooltipAndHeadingWrapper = "CreateCloudProjectCloudTypeSelection__tooltipAndHeadingWrapper__xzgHs";